<template>
  <div class="wrapper">
    <menu-ver type="superuser" name="superuser" />
    <div class="container m-3">
      <div class="table-header">
      </div>
      <table class="table" v-if="contacts && contacts.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("contact.name") }}</th>
            <th scope="col">{{ $t("contact.email") }}</th>
            <th scope="col">{{ $t("contact.type") }}</th>
            <th scope="col">{{ $t("contact.state") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in contacts" :key="item.prof_id">
            <td>{{ item.contact_nombre }}</td>
            <td>{{ item.contact_email }}</td>
            <td>{{ item.contact_tipo }}</td>
            <td>{{ item.contact_estado }}</td>
            <td style="min-width: 50pt">
              <button
                class="btn button buttonEdit"
                @click="getActions(item)"
                data-bs-toggle="modal"
                data-bs-target="#viewModal"
              >
                <i class="far fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal view -->
  <div
    class="modal fade"
    id="viewModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("contact.viewTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.name`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_nombre"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.email`) }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="contact.contact_email"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.prefix`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_prefijo"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.phone`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_telefono"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.message`) }}</label>
              <textarea
                v-model.trim="contact.contact_mensaje"
                class="form-control"
                rows="7"
                style="text-transform: uppercase"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.lang`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact_idioma"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.ip`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_ip"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.birthday`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact_fecha_nacimiento"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.dni`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_dni"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.title`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_titulo"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.type`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_tipo"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.date`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact_fecha"
                disabled
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`contact.state`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="contact.contact_estado"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="changeState"
          >
            {{ $t("contact.changeState") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-else
            class="btn button btn-return-result"
            data-bs-target="#viewModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      contacts: [
        {
          contact_nombre: "asd"
        }
      ],
      contact: {
       
      },
      contact_fecha_nacimiento: "",
      contact_idioma: "ESP",
      contact_fecha: "",
      contactId: "",
      result: "",
      loading: false,
    };
  },
  created() {
    this.getContacts();
    this.changeBreadCrumb(52);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getContacts() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=contact&ac=listado`
        );
        console.log("res", res);
        if (res.data) {
          this.contacts = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async changeState() {
      try {
        this.loading = true;
        // console.log("id", this.contact.contact_id);
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=contactar&ac=estado&id=${this.contact.contact_id}`
        );
        // console.log("res", res);
        this.result = res.data.RESULT;
        $("#viewModal").modal("hide");
        $("#responseModal").modal("show");
      } catch (error) {
        this.result = "1";
        console.error(error);
      }
      this.loading = false;
    },
    getActions(item) {
      this.contact = item;
      this.contact.contact_nombre = "aaa";
      if (item.contact_fecha_nacimiento) {
        const auxDate = item.contact_fecha_nacimiento.split("T");
        const date = auxDate[0];
        this.contact_fecha_nacimiento = date;
      }
      if (item.contact_fecha) {
        const auxDate = item.contact_fecha.split("T");
        const date = auxDate[0];
        this.contact_fecha = date;
      }
      if (item.contact_idioma === "ESP") {
        this.contact_idioma = this.$t(`languages.es`);
      } else if (item.contact_idioma === "ENG") {
        this.contact_idioma = this.$t(`languages.en`);
      }
    },
    cleanForms() {
      this.contact = {};
      this.contact_idioma = "";
      this.contact_fecha_nacimiento = "";
      this.getContacts();
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
</style>